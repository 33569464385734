<!--
 * @Author: 卢均锐morye
 * @Date: 2020-04-08 10:07:42
 * @LastEditTime: 2020-04-16 18:23:06
 -->
<template>
	<app-layout class="inner-dialog">
		<div slot="header">
			<el-row>
				<el-input
					placeholder="请输入姓名或手机号查询"
					v-model.trim="apiParams.keyWork"
					@keyup.enter.native="filter"
					style="width: 40%"
					@change="filter"
				>
					<i slot="suffix" class="el-input__icon el-icon-search search_icon" @click="filter"></i>
				</el-input>
			</el-row>
		</div>
		<el-table
			:data="tableDatas"
			ref="Table"
			class="radio-table tableBackground"
			tooltip-effect="dark"
			v-loading="pageLoading"
			@row-click="confirm"
			style="width: 100%"
		>
			<!--<el-table-column
        type="selection"
        :reserve-selection="true"
        width="60"
        align="center"
      ></el-table-column>!-->
			<el-table-column prop="name" label="姓名" width align="center"></el-table-column>
			<el-table-column prop="phoneNumber" label="手机号" width align="center"></el-table-column>
			<el-table-column prop="sexName" label="性别" width align="center"></el-table-column>
			<el-table-column prop="graduatedSchool" label="毕业院校" width align="center"></el-table-column>
			<el-table-column prop="officeName" label="应聘职位" width="200" align="center"></el-table-column>
		</el-table>
		<div slot="footer">
			<el-pagination
				style="text-align: right"
				background
				:current-page="apiParams.pageNo"
				@current-change="handleCurrentChange"
				:page-size="apiParams.pageSize"
				layout="prev, pager, next"
				:page-count="totalPage"
			>
			</el-pagination>
			<!--<div class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button
          type="primary"
          @click="confirm"
        >确认</el-button>
      </div>!-->
		</div>
	</app-layout>
</template>
<script>
import { getContributors } from '@/api/system';
import PageMixin from '../../../mixins/pageQuery';
export default {
	mixins: [PageMixin],
	data() {
		return {
			apiParams: {
				pageNo: 1,
				pageSize: 40,
				keyWork: ''
			},
			pageLoading: false,
			tableDatas: [],
			selectList: []
		};
	},
	props: {
		filterParams: {
			type: Object,
			default: () => ({})
		},
		show: Boolean
	},
	watch: {
		show: {
			handler(val) {
				if (val) {
					this.selectList = [];
					this.$refs.Table.clearSelection();
					this.filter();
				}
			}
		}
	},
	mounted() {
		this.getPageData();
	},
	methods: {
		async getPageData() {
			this.pageLoading = true;
			try {
				await this.getEmployees();
			} finally {
				this.pageLoading = false;
			}
		},
		confirm(row) {
			this.selectList = [{ id: row.id, value: row.name, resumeInterviewTime: row.resumeInterviewTime }];
			this.$emit('confirm', this.selectList);
		},
		getRowKey(row) {
			return row.id;
		},
		async getEmployees() {
			return getContributors({ request: { ...this.apiParams, ...this.filterParams } }).then((res) => {
				this.tableDatas = res.list;
				this.totalPage = res.totalPage;
			});
		}
	}
};
</script>
<style lang="scss">
.dialog-footer {
	margin-top: 18px;
	text-align: center;
}
.inner-dialog {
	.app-list-center {
		height: 800px;
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-table th,
.el-table tr {
	background-color: #f0f8ff;
}
</style>
