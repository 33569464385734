<template>
	<app-layout :need-header="false">
		<el-tree
			:data="treeData"
			show-checkbox
			node-key="id"
			ref="tree"
			:check-strictly="true"
			@check-change="checkChange"
			:render-content="renderContent"
		>
		</el-tree>
		<div slot="footer" style="text-align: center">
			<el-button @click="cancel">取消</el-button>
			<el-button @click="confirm" type="primary">确认</el-button>
		</div>
	</app-layout>
</template>
<script>
export default {
	data() {
		return {
			treeData: [],
			selectList: []
		};
	},
	props: {
		show: Boolean
	},
	mounted() {
		this.getOrgList();
	},
	watch: {
		show: {
			handler(val) {
				if (val) {
					this.selectList = [];
					this.$refs.tree.setCheckedNodes([]);
				}
			}
		}
	},
	methods: {
		getOrgList() {
			this.$axios.post('/v1/org/list', { request: {} }).then((res) => {
				if (res.code) {
					return;
				}
				this.treeData = res;
			});
		},
		confirm() {
			const rows = this.$refs.tree.getCheckedNodes();

			if (rows.length > 0) {
				this.selectList = [
					{
						id: rows[0].id,
						value: rows[0].orgName
					}
				];
				this.$emit('confirm', this.selectList);
			}
		},
		checkChange(row, status) {
			if (status) {
				this.$refs.tree.setCheckedNodes([row]);
			}
		},
		cancel() {
			this.$emit('cancel');
		},
		renderContent(h, { data }) {
			return (
				<span class="custom-tree-node">
					<span>{data.orgName}</span>
				</span>
			);
		},
		getDepUser(data) {
			this.selectList.push({ id: data.id, value: data.orgName });
		}
	}
};
</script>
