<template>
	<app-layout>
		<div slot="header">
			<el-form inline style="text-align: left">
				<el-form-item label="职族">
					<el-input v-model="apiParams.familyName" size="small" placeholder="请输入职族"></el-input>
				</el-form-item>
				<el-form-item label="职类">
					<el-input v-model="apiParams.className" size="small" placeholder="请输入职类"></el-input>
				</el-form-item>
				<el-form-item label="职位">
					<el-input v-model="apiParams.officeName" size="small" placeholder="请输入职位"></el-input>
				</el-form-item>
				<!-- <el-form-item label="岗位">
          <el-input v-model="apiParams.jobName"></el-input>
        </el-form-item> -->
				<el-form-item>
					<el-button type="primary" size="small" icon="el-icon-search" @click="filter">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table
			:data="tableDatas"
			v-loading="loading"
			class="radio-table"
			ref="Table"
			:row-key="getRowKey"
			style="width: 100%"
			highlight-current-row
			@select="handleSelectChange"
		>
			<el-table-column type="selection" :reserve-selection="true" width="60" align="center"></el-table-column>
			<el-table-column property="familyName" label="职族"></el-table-column>
			<el-table-column property="className" label="职类"></el-table-column>
			<el-table-column property="subclassName" label="职类子类"></el-table-column>
			<el-table-column property="officeName" label="职位"></el-table-column>
			<el-table-column property="officeCode" label="职级代码"></el-table-column>
			<el-table-column property="jobName" label="岗位"></el-table-column>
			<el-table-column property="officeLevel" label="职级"></el-table-column>
		</el-table>
		<div slot="footer">
			<el-pagination
				background
				style="text-align: right; margin: 20px 0"
				:current-page="apiParams.pageNo"
				@current-change="handleCurrentChange"
				layout="prev, pager, next"
				:page-size="apiParams.pageSize"
				:page-count="totalPage"
			>
			</el-pagination>
			<section style="text-align: center">
				<el-button @click="cancel">取消</el-button>
				<el-button @click="confirm" type="primary">确认</el-button>
			</section>
		</div>
	</app-layout>
</template>
<script>
import { getPositionLevelList } from '../../../api/staffSystem';
import PageMixin from '../../../mixins/pageQuery';
import { makePositionLevelTxt } from '../../../utils';
export default {
	mixins: [PageMixin],
	data() {
		return {
			tableDatas: [],
			loading: false,
			apiParams: {
				pageNo: 1,
				pageSize: 50,
				familyName: '',
				className: '',
				officeName: '',
				jobName: '',
				resumeId: this.resumeId || ''
			},
			selectList: []
		};
	},
	mounted() {
		this.getPageData();
	},
	props: {
		show: Boolean,
		resumeId: { type: String, default: () => '' }
	},
	watch: {
		show: {
			handler(val) {
				if (val) {
					this.selectList = [];
					this.$refs.Table.clearSelection();
					this.filter();
				}
			}
		},
		resumeId: {
			handler(val) {
				this.resumeId = val;
				this.filter();
			}
		}
	},
	methods: {
		async getPageData() {
			this.loading = true;
			await this.getPositionLevels();
			this.loading = false;
		},
		confirm() {
			this.$emit('confirm', this.selectList);
		},
		getRowKey(row) {
			return row.officeLevel;
		},
		cancel() {
			this.$emit('cancel');
		},
		handleSelectChange(section, row) {
			this.$refs.Table.clearSelection();
			this.$refs.Table.toggleRowSelection(row, true);
			this.selectList = [{ id: row.officeLevel, value: makePositionLevelTxt(row), row }];
		},
		async getPositionLevels() {
			return getPositionLevelList(this.apiParams).then((res) => {
				this.tableDatas = res.list;
				this.totalPage = res.totalPage;
			});
		}
	}
};
</script>
<style scoped lang="scss">
.dialog-footer {
	text-align: center;
	margin-top: 18px;
}
</style>
